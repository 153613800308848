<template>
  <b-overlay
      id="am_modal_content"
      :show="showOverlay"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
      :variant="isDarkMode?'dark':'white'"
      opacity="0.8"
  >
    <slot/>
  </b-overlay>
</template>

<script>
import {
BOverlay,
} from 'bootstrap-vue'
export default {
  name: "AmOverlay",
  components:{
    BOverlay,
  },
  props:{
    showOverlay: { type: Boolean, default: false },
  },
  computed: {
    isDarkMode() {
      return this.$store.getters['appConfig/skin'] === 'dark'
    },
  },
}
</script>

<style scoped>

</style>
